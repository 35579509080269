import React, { Fragment, useContext, useEffect, useState } from "react";
import { Logo, TopbarInfos, FooterBottomData } from "@/data";
import Link from "@/components/link";
import Img from "@/components/img";
import { MenuContext } from "@/context/menu-context";
import { StaticQuery, graphql } from "gatsby";
import Search from "@/components/search";
const searchIndices = [
  { name: `Pages`, title: `Pages` },
  { name: "undefined", title: "Articles & Events" },
];

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <Fragment>
      <header className="header_area">
        <div className="container">
          <div className="header_social">
            <ul className="hd_social_icons">
              {FooterBottomData.social.map(({ icon, url }, index) => (
                <li key={`header-social-${index}`}>
                  <a href={url}>
                    <i className={`fab ${icon}`}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="header_contact text-end">
            <ul className="hd_contact">
              {TopbarInfos.map(({ icon, tagLine, text, url }, index) => (
                <li key={`header-info-${index}`}>
                  <i className={icon}></i> {tagLine}
                  <a href={url}> {text}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>

      <div
        className={`main_menu_area ${
          true === sticky
            ? " stricky stricky-fixed slideInDown animated"
            : " stricky slideIn animated"
        }`}
      >
        <div className="container">
          <div className="main_menu_area__left">
            <Link href="/">
              <Img
                src={Logo.dark}
                alt="Ferrara Fiorenza PC Logo"
                className="logo"
                layout="fixed"
              />
            </Link>
            <div className="flex gap-4">
              <button
                className="mobile-menu__toggler"
                onClick={handleMenuClick}
              >
                <i className="fa fa-bars"></i>
              </button>
            </div>
          </div>
          <StaticQuery
            query={graphql`
              query navQuery {
                nav: sanityNavigation {
                  title
                  sections {
                    title
                    links {
                      linkUrl
                      title
                      external
                      link {
                        slug {
                          current
                        }
                      }
                    }
                    linkUrl
                    target {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            `}
            render={(data) => (
              <div className="main_menu_area__right">
                <nav className="main_menu_area__menu">
                  <ul className="nav navbar-nav navigation-box">
                    {data.nav.sections.map((navItem, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={
                              navItem.linkUrl !== null
                                ? navItem.linkUrl
                                : "/" + navItem.target.slug.current
                            }
                          >
                            {navItem.title}
                          </a>
                          {navItem.links.length > 0 ? (
                            <ul className="sub-menu">
                              {navItem.links.map((subLinks, index) => (
                                <li key={index}>
                                  <a
                                    href={
                                      subLinks.linkUrl !== null
                                        ? subLinks.linkUrl
                                        : "/" + subLinks.link.slug.current
                                    }
                                  >
                                    {subLinks.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
                <Search indices={searchIndices} />
              </div>
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderOne;
